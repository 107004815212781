<template>
<div>
    <Modal :options="{width: '40vw', close: true}" @close="$emit('close')" >
    <div class="title">{{ configuracion.id ? 'Editar Configuración' : 'Crear nueva configuración' }}</div>
    <div class="body">
        <div class="row form-group">
            <label for="clave" class="col-form-label col-sm-3"> Clave </label>
            <div class="col-sm-9"><input v-model="configuracion.clave" type="text" name="clave" id="direccion" class="form-control"></div>
        </div>  
        <div class="row form-group">
            <label for="descricpion" class="col-form-label col-sm-3"> Descripción </label>
            <div class="col-sm-9"><input v-model="configuracion.descripcion" type="text" name="descripcion" id="direccion" class="form-control"></div>
        </div> 
        <div class="row form-group">
            <label for="valor" class="col-form-label col-sm-3"> valor </label>
            <div class="col-sm-9"> <textarea v-model="configuracion.valor" class="form-control" name="valor" id="valor" cols="30" rows="10"></textarea></div>
        </div> 
        <template v-if="configuracion.id">
        <div class="row form-group">
            <div class="col-sm-3">
                <label for="estatus" class="col-form-label">Estatus</label>
            </div>
            <div class="col-sm-9">
                <select  class="form-control" v-model="configuracion.estatus" name="estatus" id="estatus">
                    <option value="null">Selecciona una opción</option>
                    <option value="0">Inactivo</option>
                    <option value="1">Activo</option>
                </select>
            </div>
        </div>
        </template>                            
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <template>
                <button type="button" class="btn btn-primary mr-2" @click="guardar_configuracion"> Guardar </button>
                <button type="button" class="btn btn-danger mr-2" @click="$emit('close')"> cancelar </button>
              </template>
            </div>
          </div>
        </div>        
    </Modal>
</div>
  
</template>

<script>
import Modal from '@/components/Modal';
import apiClientes from '@/apps/clientes/api/clientes';

export default {
    components:{
        Modal,
    },
    props:{
        editar_configuracion:null,
    },
    data(){
        return{
            configuracion:{
                id:null,
                clave:null,
                descripcion:null,
                valor:null,
                estatus:null
            }
        }
    },
    methods:{
        async guardar_configuracion(){
            try {
                if (!this.configuracion.clave)
                    return this.$helper.showMessage('Error','Debes definir la clave','error','alert');

                if (!this.configuracion.descripcion)
                    return this.$helper.showMessage('Error','Debes definir la descripción','error','alert');

                if (!this.configuracion.valor)
                    return this.$helper.showMessage('Error','Debes definir el valor','error','alert');

                if(this.configuracion.id==null)
                    await apiClientes.crear_configuracion(this.configuracion);
                else
                    await apiClientes.editar_configuracion(this.configuracion.id, this.configuracion);

                this.$emit('update');
                this.$emit('close');

            } catch (e) {
                this.$log.info('error', e)
                this.$helper.showAxiosError(e,'Error');
            }
        },

    },
    mounted(){
        if(this.editar_configuracion !== null)
            this.configuracion = this.editar_configuracion;
    },
}
</script>

<style>

</style>